import React from 'react';
import { Text } from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';
import { StyledFirebaseAuth } from 'react-firebaseui';
import firebase from 'gatsby-plugin-firebase';

export const openSigninModal = (message?: string, onSignInSuccess?: () => void) => {
  modals.openContextModal({
    centered: true,
    padding: 'xl',
    modal: 'signin',
    title: 'Sign In to PromLabs',
    innerProps: { message: message || 'Sign in to use the site fully:', onSignInSuccess },
  });
};

const SigninModal = ({ context, id, innerProps }: ContextModalProps<{ message: string; onSignInSuccess?: () => void }>) => (
  <>
    <Text size="md" mt="sm" mb="xl" ta="center">
      {innerProps.message}
    </Text>
    <StyledFirebaseAuth
      uiConfig={{
        signInFlow: 'popup',
        // signInSuccessUrl: '/',
        callbacks: {
          // Avoid redirects after sign-in.
          signInSuccessWithAuthResult: () => {
            modals.closeAll();
            if (innerProps.onSignInSuccess) {
              innerProps.onSignInSuccess();
            }
            return false;
          },
        },
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          // auth.TwitterAuthProvider.PROVIDER_ID,
          firebase.auth.GithubAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ],
      }}
      firebaseAuth={firebase.auth()}
    />
  </>
);

export default SigninModal;
