import firebase from 'gatsby-plugin-firebase';
import React, { FC, useEffect, useState } from 'react';

interface VisitedSections {
  [training: string]: { [section: string]: Set<string> };
}

interface AuthInfo {
  ready: boolean;
  authError: Error | null;
  user: firebase.User | null;
  setUser: (user: firebase.User | null) => void;

  // List of Paddle product IDs.
  trainings: number[];
  addTraining: (training: number) => void;
  loadTrainingsError: Error | null;

  visitedSections: VisitedSections;
  addVisitedSection: (training: string, section: string, subsection: string) => void;
  visitedReady: boolean;

  siteEnabled: boolean;
  setSiteEnabled: (enabled: boolean) => void;
  siteEnabledReady: boolean;

  signOut: () => void;
}

export const AuthContext = React.createContext<AuthInfo>({
  ready: false,
  authError: null,
  user: null,
  setUser: (user) => {
    return;
  },
  trainings: [],
  addTraining: (training: number) => {
    return;
  },
  loadTrainingsError: null,
  visitedSections: {},
  addVisitedSection: (training: string, section: string, subsection: string) => {
    return;
  },
  visitedReady: false,
  siteEnabled: true,
  setSiteEnabled: (enabled: boolean) => {
    return;
  },
  siteEnabledReady: false,
  signOut: () => {
    return;
  },
});

export const AuthProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [ready, setReady] = useState<boolean>(false);
  const [authError, setAuthError] = useState<Error | null>(null);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [trainings, setTrainings] = useState<number[]>([]);
  const [loadTrainingsError, setLoadTrainingsError] = useState<Error | null>(null);
  const [visitedSections, setVisitedSections] = useState<VisitedSections>({});
  const [visitedReady, setVisitedReady] = useState<boolean>(false);
  const [siteEnabled, setSiteEnabled] = useState<boolean>(true);
  const [siteEnabledReady, setSiteEnabledReady] = useState<boolean>(false);

  const signOut = () => {
    setReady(true);
    setAuthError(null);
    setUser(null);
    setTrainings([]);
    setLoadTrainingsError(null);
    setVisitedSections({});
    setVisitedReady(false);
    firebase.auth().signOut();
  };

  // Sign in users automatically via Okta on the Chronosphere platform.
  useEffect(() => {
    if (process.env.GATSBY_PLATFORM !== 'chronosphere') {
      return;
    }

    if (!ready) {
      return;
    }

    if (user === null) {
      const provider = new firebase.auth.OAuthProvider(process.env.GATSBY_FIREBASE_OIDC_PROVIDER || 'oidc.okta');
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          // console.log('Sign in result:', result);
        })
        .catch((error) => {
          setAuthError(error);
        });
    }
  }, [ready, user]);

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      function (user) {
        if (process.env.GATSBY_PLATFORM === 'acend') {
          // Fetch site enabled status from Firebase.
          firebase
            .firestore()
            .collection('site-settings')
            .doc('acend')
            .get()
            .then((doc) => {
              if (!doc.exists && doc.metadata.fromCache) {
                throw new Error('Unable to fetch the site settings from Firebase.');
              }
              setSiteEnabled(doc.data()?.siteEnabled);
            })
            .catch((error: Error) => {
              setLoadTrainingsError(error);
            })
            .finally(() => setSiteEnabledReady(true));
        }

        if (user) {
          setUser(user);

          // Fetch list of purchased trainings from Firebase.
          firebase
            .firestore()
            .collection('orders')
            .where('userUID', '==', user.uid)
            .get()
            .then((orders) => {
              if (orders.empty && orders.metadata.fromCache) {
                throw new Error('Unable to fetch the list of purchased trainings for the logged-in user from Firebase.');
              }
              const trainings: any[] = [];
              orders.forEach((order) => {
                trainings.push(order.data().productID);
              });
              setTrainings(trainings);
            })
            .catch((error: Error) => {
              setLoadTrainingsError(error);
            })
            .finally(() => setReady(true));

          if (process.env.GATSBY_PLATFORM === 'chronosphere') {
            // Set the user ID and email for Sprig.
            (window as any).Sprig('setUserId', user.providerData[0]?.uid);
            (window as any).Sprig('setEmail', user.providerData[0]?.email);

            // Fetch list of visited sections from Firebase.
            firebase
              .firestore()
              .collection('users')
              .doc(user.uid)
              .collection('section-visits')
              .get()
              .then((sections) => {
                // TODO: Should the "&&" be an "||"? Check here and elsewhere.
                if (sections.empty && sections.metadata.fromCache) {
                  throw new Error('Unable to fetch the list of visited sections for the logged-in user from Firebase.');
                }
                const visited: VisitedSections = {};
                sections.forEach((section) => {
                  const data = section.data();
                  if (!visited[data.training]) {
                    visited[data.training] = {};
                  }
                  if (!visited[data.training][data.section]) {
                    visited[data.training][data.section] = new Set<string>();
                  }
                  visited[data.training][data.section].add(data.subsection);
                });
                setVisitedSections(visited);
              })
              .catch((error: Error) => {
                setLoadTrainingsError(error);
              })
              .finally(() => setVisitedReady(true));
          }
        } else {
          signOut();
        }
      },
      function (error: firebase.auth.Error) {
        console.log('Encountered an error signing in:', error);
      }
    );

    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ready,
        authError,
        user,
        setUser,
        trainings,
        addTraining: (training: number) => {
          setTrainings([...trainings, training]);
        },
        loadTrainingsError,
        visitedSections,
        addVisitedSection: (training: string, section: string, subsection: string) => {
          setVisitedSections({
            ...visitedSections,
            [training]: {
              ...(visitedSections[training] || {}),
              [section]: new Set<string>([
                ...Array.from(visitedSections[training]?.[section] || new Set<string>()),
                subsection,
              ]),
            },
          });
        },
        visitedReady,
        siteEnabled,
        setSiteEnabled,
        siteEnabledReady,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
